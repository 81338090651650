import React, { useMemo, useState } from "react";
import { WarningOutlined } from "@ant-design/icons";
import { App, Button, Empty } from "antd5";
import { Link } from "wouter";

import { generateNoticeUrl } from "components/notices/utils";
import { LinkCell } from "components/table_components/LinkCell";
import SignalsContainer from "components/tags/SignalsContainer";
import { getBuyerSupplierRelationshipDestination } from "lib/appDestinations";
import { ColumnHeader } from "lib/core_components/ColumnHeader";
import RedactedWrapper, { RedactedLink } from "lib/core_components/RedactedWrapper";
import { Table } from "lib/core_components/Table";
import { ColumnType, commonTableColumns } from "lib/core_components/Table/ColumnTypes";
import { PartnersCompetitorsRequest, Signal } from "lib/generated/app-api";
import { useSpendExists } from "lib/generated/spend-data-api/spendDataManagementAPI";
import { useBuyersPartnersCompetitors } from "lib/hooks/api/buyer/useBuyersPartnersCompetitors";
import { useRestrictedClickthrough } from "lib/hooks/useRestrictedRowClick";
import { useOpenApi } from "lib/openApiContext";
import { SignalCategory as SigCategory, SignalFilters } from "lib/StotlesApi";
import { tagColourFromSignal } from "lib/tagUtils";
import { BuyerSummary } from "lib/types/models";
import { EM_DASH } from "lib/utils";
import { toHumanRelativeTimeFromNow } from "lib/utils/relative_time";
import { StaticSpendDataCell } from "./SpendDataCell";
import { PaginationConfig, SortState } from "./types";

export type PartnerCompetitorColumns =
  | "nameAndLastActivity"
  | "signals"
  | "allActivity"
  | "relevantAwards"
  | "upcomingExpiries"
  | "spendData";

type SupplierData = {
  id: string | number;
  guid: string;
  name: string;
  allActivityCount: number;
  upcomingExpiriesCount: number;
  relevantAwardsCount: number;
  keywords?: Array<string> | null;
  signals: Array<Signal>;
  lastActivity: string | null;
  totalSpendAmount?: number | null;
};

type SupplierSignalCategory = "Partners" | "Competitors";
type PartnersCompetitorsTableProps = {
  filters: PartnersCompetitorsRequest;
  nameColumnTitle: string;
  fields: PartnerCompetitorColumns[];
  buyer: BuyerSummary;
  onRowClick: (s: SupplierData) => string;
  signalCategory: SupplierSignalCategory;
};

export const DEFAULT_PAGE_AND_SORT: {
  pagination: PaginationConfig;
  sort: { field?: string; order: "ASC" | "DESC" };
} = {
  pagination: {
    pageSize: 10,
    current: 1,
  },
  sort: {
    field: "relevant_awards",
    order: "DESC",
  },
};

const LEAD_SIGNAL_SORT_COLUMN = "lead_signals";

function getSortOrder(sort: SortState, key: string): "ascend" | "descend" | undefined {
  if (sort.signal_sort) {
    if (key === LEAD_SIGNAL_SORT_COLUMN) {
      // if `signal_sort` is defined it means it overrides the regular sort and we won't show sort.field as the sorted column
      return sort.signal_sort === "ASC" ? "ascend" : "descend";
    }
    return undefined;
  }

  if (sort.field === key) {
    return sort.order === "ASC" ? "ascend" : "descend";
  }
  return undefined;
}

const SignalColumn = ({
  name,
  guid,
  type,
}: {
  name: string;
  guid: string;
  type: "supplier" | "partner" | "competitor";
}) => {
  return (
    <RedactedWrapper
      requiredDataType={"SUPPLIERS"}
      redactContent={<RedactedLink textToRedact={name} />}
      showModalOnClick={false}
    >
      {/* Without the stopPropagation, will continue on to the restrictedRowClick, which opens the modal */}
      {window.guestUser ? (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a>{name}</a>
      ) : (
        <Link
          to={getBuyerSupplierRelationshipDestination(guid, "", type)}
          onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => e.stopPropagation()}
        >
          {name}
        </Link>
      )}
    </RedactedWrapper>
  );
};

function anySignalFilterActive(filters: SignalFilters) {
  return (
    filters.keywords.length > 0 || filters.partners.length > 0 || filters.competitors.length > 0
  );
}

function SpendColumnHeader({
  activeFilters,
  buyer,
}: {
  activeFilters: SignalFilters;
  buyer: BuyerSummary;
}) {
  const { message } = App.useApp();
  const { data } = useSpendExists(
    { buyerIds: [buyer.guid] },
    {
      query: {
        enabled: !window.guestUser,
      },
    },
  );
  const api = useOpenApi();

  if (data && !data.exists) {
    return (
      <ColumnHeader
        tooltipProps={{
          title: "No spend data for this buyer",
          content: (
            <div>
              <p>
                We currently do not have spend data for this buyer, this could be due to them not
                publishing the information or us not yet having covered it.
              </p>
              <Button
                type="primary"
                onClick={async () => {
                  await api.requestSpend({ guid: buyer.guid });
                  void message.success(
                    "Spend data request sent! We will be in touch with an update within 5 business days.",
                    3,
                  );
                }}
              >
                Request this data
              </Button>
            </div>
          ),
        }}
        iconColour="warning"
        icon={<WarningOutlined />}
        header="Total spend"
      />
    );
  }
  if (anySignalFilterActive(activeFilters)) {
    return (
      <ColumnHeader
        tooltipProps={{
          title: "Out of sync with notice filters",
          content: (
            <span>
              Due to how spend data is documented it is not possible to filter it by keywords in the
              same way as notices.
            </span>
          ),
        }}
        iconColour="warning"
        icon={<WarningOutlined />}
        header="Total spend"
      />
    );
  }
  return <>Total spend</>;
}

function getPartnerCompetitorColumns(
  nameColumnTitle: string,
  fields: PartnerCompetitorColumns[],
  sort: SortState,
  activeFilters: SignalFilters,
  buyer: BuyerSummary,
  signalCategory: SupplierSignalCategory,
): ColumnType<SupplierData>[] {
  const getRelationshipUrl = (guid: string) =>
    `/${signalCategory === "Partners" ? "partner" : "competitor"}/${guid}`;

  const nameAndLastActivity = {
    key: "name_and_last_activity",
    title: nameColumnTitle,
    render: (_: unknown, s: SupplierData) => {
      return (
        <div>
          <SignalColumn
            name={s.name}
            guid={s.guid}
            type={signalCategory === "Partners" ? "partner" : "competitor"}
          />
          <div>
            {s.lastActivity ? `Last active ${toHumanRelativeTimeFromNow(s.lastActivity)}` : EM_DASH}
          </div>
        </div>
      );
    },
    sorter: false,
    sizeConfig: {
      xlarge: 600,
      large: 500,
      medium: 400,
      small: 250,
    },
  };

  const allActivity = {
    title: "All notices",
    key: "all_activity",
    sorter: true,
    sortOrder: getSortOrder(sort, "all_activity"),
    sizeConfig: {
      large: 185,
      medium: 185,
      small: 120,
    },
    render: (_: unknown, s: SupplierData) => (
      <LinkCell value={s.allActivityCount} destination={getRelationshipUrl(s.guid)} />
    ),
  };

  const upcomingExpiries = {
    title: "Upcoming expiries",
    render: (_: unknown, s: SupplierData) => (
      <LinkCell
        value={s.upcomingExpiriesCount}
        destination={generateNoticeUrl(
          {
            signals: [s.guid],
            buyers: [buyer.guid],
            expiryDate: { filter: { relativeFrom: "PT0S" }, hideNulls: true },
            stage: ["AWARDED"],
            sort: { field: "expiry_date", order: "ASC" },
          },
          getRelationshipUrl(s.guid),
        )}
      />
    ),
    key: "upcoming_expiries",
    sorter: true,
    sortOrder: getSortOrder(sort, "upcoming_expiries"),
    sizeConfig: {
      large: 185,
      medium: 185,
      small: 120,
    },
  };

  const relevantAwards = {
    title: "Relevant notices",
    key: "relevant_awards",
    sorter: true,
    sortOrder: getSortOrder(sort, "relevant_awards"),
    sizeConfig: {
      large: 185,
      medium: 185,
      small: 120,
    },
    render: (_: unknown, s: SupplierData) => (
      <LinkCell value={s.relevantAwardsCount} destination={getRelationshipUrl(s.guid)} />
    ),
  };

  const signals = {
    ...commonTableColumns.signalsColumn,

    title: "Signals",
    key: "signals",
    render: (_: unknown, s: SupplierData) => {
      const signals = s.signals.map((s) => ({
        ...s,
        colour: tagColourFromSignal(s.category),
      }));
      return (
        <SignalsContainer
          signals={signals}
          maxSignals={4}
          redactedSignalCategories={[SigCategory.COMPETITOR, SigCategory.PARTNER]}
          requiredDataType="BUYERS"
          contextSource="In-row"
        />
      );
    },
    sorter: false,
    sizeConfig: {
      xlarge: 250,
      large: 250,
      medium: 220,
      small: 190,
    },
  };

  const spendData = {
    title: <SpendColumnHeader activeFilters={activeFilters} buyer={buyer} />,
    render: (_: unknown, s: SupplierData) => {
      return <StaticSpendDataCell amount={s.totalSpendAmount} buyerId={buyer.guid} />;
    },
    key: "total_spend_amount",
    sorter: true,
    sortOrder: getSortOrder(sort, "total_spend_amount"),
    sizeConfig: {
      large: 185,
      medium: 185,
      small: 120,
    },
  };

  const allFields = {
    nameAndLastActivity,
    allActivity,
    upcomingExpiries,
    relevantAwards,
    spendData,
    signals,
  } as const;

  return fields.map((f) => allFields[f]);
}

export function PartnersCompetitorsTable({
  filters,
  nameColumnTitle,
  fields,
  buyer,
  onRowClick,
  signalCategory,
}: PartnersCompetitorsTableProps): JSX.Element {
  const [{ sort, pagination }, setQueryOptions] = useState(DEFAULT_PAGE_AND_SORT);
  const { data, isLoading, isError } = useBuyersPartnersCompetitors({
    buyerId: buyer.id,
    request: {
      ...filters,
      sort: sort.field as string | undefined,
      sortOrder: sort.order,
      offset: (pagination.current - 1) * pagination.pageSize,
      limit: pagination.pageSize,
    },
  });
  const tableColumns = useMemo(
    () =>
      getPartnerCompetitorColumns(
        nameColumnTitle,
        fields,
        sort,
        {
          competitors: filters.competitors || [],
          keywords: filters.keywords || [],
          partners: filters.partners || [],
        },
        buyer,
        signalCategory,
      ),
    [nameColumnTitle, fields, sort, filters, buyer, signalCategory],
  );

  const onRow = useRestrictedClickthrough({
    requiredDataType: "SUPPLIERS",
    getDestination: onRowClick,
  });

  return (
    <Table<SupplierData>
      rowKey="name"
      dataSource={data?.results || []}
      columns={tableColumns}
      loading={isLoading}
      onChange={(page, _, sort) => {
        if (Array.isArray(sort)) {
          sort = sort[0];
        }
        setQueryOptions({
          pagination: {
            pageSize: page.pageSize || pagination.pageSize,
            current: page.current || pagination.current,
          },
          sort: {
            order: sort.order === "descend" ? "DESC" : "ASC",
            field: sort.columnKey as string | undefined,
          },
        });
      }}
      locale={{
        emptyText: isError ? (
          <Empty
            image={<WarningOutlined style={{ fontSize: 100 }} />}
            description="Sorry something has gone wrong"
          />
        ) : undefined,
      }}
      onRow={onRow}
      scroll={{ x: true }}
      pagination={{ ...pagination, total: data?.pagingInfo.totalResults }}
    />
  );
}
