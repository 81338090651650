import React from "react";
import { Control, FieldValues, Path } from "react-hook-form";

import { SupplierSelect } from "../../../form_components/SupplierSelect";
import { SupplierTagMultiselectExplanation } from "../../../form_components/SupplierTagMultiselect";

import css from "./SupplierInput.module.scss";

type SupplierInputProps<T extends FieldValues> = {
  competitorFieldName: Path<T>;
  partnerFieldName: Path<T>;
  control: Control<T, unknown>;
};

export default function SupplierInput<T extends FieldValues>({
  competitorFieldName,
  partnerFieldName,
  control,
}: SupplierInputProps<T>) {
  return (
    <div className={css.container}>
      <div className={css.section}>
        <div className={css.header}>
          <p className={css.title}>Competitors</p>
          <SupplierTagMultiselectExplanation supplierType="competitor" useSupplierName={false} />
        </div>
        <SupplierSelect
          name={competitorFieldName}
          label=""
          control={control}
          mode="multiple"
          placeholder="Search competitors..."
          allowClear
          idType="guid"
          tagVariant="error"
        />
      </div>
      <div className={css.section}>
        <div className={css.header}>
          <p className={css.title}>Partners</p>
          <SupplierTagMultiselectExplanation supplierType="partner" useSupplierName={false} />
        </div>
        <SupplierSelect
          name={partnerFieldName}
          label=""
          control={control}
          mode="multiple"
          placeholder="Search partners..."
          allowClear
          idType="guid"
          tagVariant="warning"
        />
      </div>
    </div>
  );
}
