import React, { useEffect, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { Divider, InputNumber } from "antd5";

import { SelectBuyerList } from "components/form_components/BuyerListSelect";
import { BuyerSelect } from "components/form_components/BuyerSelect";
import { BuyerTypeSelect } from "components/form_components/BuyerTypeSelect";
import { CountryRegionSelect } from "components/form_components/CountryRegionSelect";
import CpvCodeSelect from "components/form_components/CpvCodeSelect";
import { Checkbox, DateFilter, DateRange, Radio, Select } from "components/form_components/Inputs";
import { KeywordSelect } from "components/form_components/keyword_select/KeywordSelect";
import { NewSupplierSelect } from "components/form_components/NewSupplierSelect";
import { SignalSelect } from "components/form_components/SignalSelect";
import { SupplierSelect } from "components/form_components/SupplierSelect";
import { cpvDimensionSelectOptions } from "lib/data/optionItems";
import { useSignalSettingsGQL } from "lib/hooks/api/teams/useSignalSettingsGQL";
import {
  CPV_CODES_FILTER,
  FILTER_COLLAPSE_FRAMEWORKS,
  KEYWORDS_ON_THE_FLY,
  LAYER_CAKE_FILTERS,
  NEW_SUPPLIER_FILTER,
  useVariableValue,
} from "../../lib/featureFlags";
import { camelToSentence, isObjEmpty } from "../../lib/utils";
import FilterCollapse from "../filter_form/filter_collapse/FilterCollapse";
import FilterCollapseHeader from "../filter_form/filter_collapse_header/FilterCollapseHeader";
import FilterFormTitle from "../filter_form/filter_form_title/FilterFormTitle";
import { countFilters } from "../filter_form/utils";
import { frameworkFilterContents } from "./filter_groups";
import FrameworkStageButtons from "./FrameworkStageButtons";
import { useFrameworksTracking } from "./tracking";
import {
  AWARD_TYPE_OPTIONS,
  BUYER_PARTICIPANT_OPTIONS,
  DEFAULT_FILTERS,
  FrameworkFilters,
  PROCEDURE_TYPE_OPTIONS,
} from "./utils";

import css from "./FilterForm.module.scss";

export const groupedKeys: Record<FilterGroupKeys, (keyof FrameworkFilters)[]> = {
  keywords: ["keywords", "excludeKeywords"],
  signals: ["signals"],
  procurementStage: ["stage"],
  framework: ["procedureType", "awardType"],
  contractValue: ["value"],
  contractDates: ["closeDate", "startDate", "endDate"],
  buyers: ["buyerIds", "buyerListIds", "buyerCategories", "buyerCountryRegions"],
  suppliers: ["supplierIds"],
  cpvCode: ["cpvDimensions", "cpvCodes"],
};

export type FilterSections = "suppliers" | "buyers";

export type FilterFormProps = {
  onChange: (filters: FrameworkFilters) => void;
  filters: FrameworkFilters;
  hiddenSections?: FilterSections[];
  showTitle: boolean;
  onClose: () => void;
  activeGroups: string[];
  onActiveGroupsChange: (group: string[]) => void;
};

type FilterGroupKeys = keyof typeof frameworkFilterContents;

export function FilterForm({
  onChange,
  filters,
  hiddenSections = [],
  showTitle,
  onClose,
  activeGroups,
  onActiveGroupsChange,
}: FilterFormProps) {
  const {
    control,
    watch,
    handleSubmit,
    setValue,
    reset,
    formState: { touchedFields },
  } = useForm<FrameworkFilters>({
    defaultValues: filters,
  });
  const previousFilters = useRef(filters);

  const { data: signalSettings } = useSignalSettingsGQL();
  const tracking = useFrameworksTracking();

  const isCollapseEnabled = useVariableValue(FILTER_COLLAPSE_FRAMEWORKS, false);

  const enableNewSupplierFilter = useVariableValue(NEW_SUPPLIER_FILTER, false);
  const keywordsOnTheFlyFilter = useVariableValue(KEYWORDS_ON_THE_FLY, false);
  const cpvCodesFilter = useVariableValue(CPV_CODES_FILTER, false);
  const isLayerCakeFiltersEnabled = useVariableValue(LAYER_CAKE_FILTERS, false);

  // submit form onChange
  useEffect(() => {
    const subscription = watch(() =>
      handleSubmit((d) => {
        tracking.filterFormChanged(previousFilters.current, d, signalSettings);
        previousFilters.current = d;
        onChange(d);
      })(),
    );
    return () => subscription.unsubscribe();
  }, [handleSubmit, onChange, signalSettings, tracking, watch]);

  const data = watch();

  function hideNullsWhenSet(fieldName: "closeDate" | "startDate" | "endDate", value: DateFilter) {
    // don't set when clearing field
    const hasTransitionedToTruthy = Object.keys(value).some((field) => {
      const prevFieldValue = previousFilters.current?.[fieldName]?.filter;
      return isObjEmpty(prevFieldValue) && value[field];
    });

    // don't set if checkbox has been changed manually already
    if (hasTransitionedToTruthy && !touchedFields[fieldName]?.hideNulls) {
      setValue(`${fieldName}.hideNulls`, true);
    }
  }

  const counts = countFilters(filters, groupedKeys);

  function clearFilter(key: FilterGroupKeys) {
    const keys = groupedKeys[key];
    keys.forEach((k) => {
      setValue(k, DEFAULT_FILTERS[k]);
    });
  }

  const filterProps = {
    control,
    data,
    hideNullsWhenSet,
    isBuyerIdsFilterApplied: !!filters.buyerIds,
  };

  function getFilterGroups() {
    let items = (Object.keys(groupedKeys) as FilterGroupKeys[]).map((key) => {
      const ContentComponent = frameworkFilterContents[key];

      // Hide for now as we test with users
      // TODO: enable or remove once testing complete
      // const tag = (filterName: string) => {
      //   if (
      //     isLayerCakeFiltersEnabled &&
      //     ["Keywords", "Suppliers", "CPV code"].includes(filterName)
      //   ) {
      //     return <NewIcon className={css.newIcon} caps={true} />;
      //   }
      //   return null;
      // };

      return {
        key,
        header: (
          <FilterCollapseHeader
            title={key === "cpvCode" ? "CPV code" : camelToSentence(key)}
            count={counts[key]}
            clear={() => clearFilter(key)}
            // tag={tag}
          />
        ),
        content: <ContentComponent {...filterProps} />,
      };
    });

    if (hiddenSections.includes("suppliers"))
      items = items.filter((item) => item.key !== "suppliers");

    if (hiddenSections.includes("buyers")) items = items.filter((item) => item.key !== "buyers");

    if (isLayerCakeFiltersEnabled) items = items.filter((item) => item.key !== "signals");

    return items;
  }

  const items = getFilterGroups();

  function clearAll() {
    reset({ ...DEFAULT_FILTERS, searchText: filters.searchText || "" });
  }

  return (
    <form>
      {showTitle && (
        <FilterFormTitle
          clearFilters={clearAll}
          showClear={Object.values(counts).some(Boolean)}
          onClose={onClose}
        />
      )}
      {isCollapseEnabled && (
        <FilterCollapse items={items} value={activeGroups} onChange={onActiveGroupsChange} />
      )}
      {!isCollapseEnabled && (
        <>
          <h3 className={css.sectionTitle}>Framework details</h3>
          <div className={css.filterSection}>
            {keywordsOnTheFlyFilter && (
              <>
                <KeywordSelect name="keywords" label="Include keywords" control={control} />
                <KeywordSelect
                  name="excludeKeywords"
                  label="Exclude frameworks that mention"
                  type="excludeKeywords"
                  control={control}
                />
              </>
            )}
            <SignalSelect
              name="signals"
              label="Signals"
              control={control}
              placeholder="Select..."
              allowClear
            />
            <Controller
              name="stage"
              control={control}
              render={({ field }) => (
                <div className={css.inputContainer}>
                  <label htmlFor="value" className={css.inputLabel}>
                    <h3>Framework stage</h3>
                  </label>
                  <FrameworkStageButtons {...field} checkedStages={field.value ?? []} />
                </div>
              )}
            />

            <Select
              name="procedureType"
              control={control}
              label="Framework type"
              options={PROCEDURE_TYPE_OPTIONS}
              mode="multiple"
              optionFilterProp="label"
              allowClear
            />

            <Select
              name="awardType"
              control={control}
              label="Award type"
              options={AWARD_TYPE_OPTIONS}
              mode="multiple"
              optionFilterProp="label"
              allowClear
            />

            <div className={css.inputContainer}>
              <label htmlFor="value" className={css.inputLabel}>
                <h3>Value</h3>
              </label>
              <div className={css.valueFields}>
                <Controller
                  name="value.from"
                  control={control}
                  rules={{ min: 0 }}
                  render={({ field, fieldState }) => (
                    <InputNumber
                      {...field}
                      status={fieldState.error && "error"}
                      min={0}
                      placeholder="From"
                    />
                  )}
                />
                -
                <Controller
                  name="value.to"
                  control={control}
                  rules={{ min: data.value?.from || 0 }}
                  render={({ field, fieldState }) => (
                    <InputNumber
                      {...field}
                      status={fieldState.error && "error"}
                      min={0}
                      placeholder="To"
                    />
                  )}
                />
              </div>
              <Checkbox
                label=""
                name="value.hideNulls"
                control={control}
                fieldLabel="Hide frameworks that don't have a value"
              />
            </div>
            {cpvCodesFilter ? (
              <CpvCodeSelect name="cpvCodes" label="CPV Codes" control={control} />
            ) : (
              <Select
                name="cpvDimensions"
                label="Sector"
                control={control}
                options={cpvDimensionSelectOptions}
                optionFilterProp="label"
                mode="multiple"
                placeholder="Select"
                allowClear
              />
            )}
          </div>
          <Divider />
          <h3 className={css.sectionTitle}>Framework Dates</h3>
          <div className={css.filterSection}>
            <div>
              <DateRange
                name="closeDate.filter"
                control={control}
                label="Close date"
                onChange={(v) => hideNullsWhenSet("closeDate", v)}
              />
              <Checkbox
                label=""
                name="closeDate.hideNulls"
                control={control}
                fieldLabel="Hide frameworks without close dates"
              />
            </div>
            <div>
              <DateRange
                name="startDate.filter"
                control={control}
                label="Start date"
                onChange={(v) => hideNullsWhenSet("startDate", v)}
              />
              <Checkbox
                label=""
                name="startDate.hideNulls"
                control={control}
                fieldLabel="Hide frameworks without start dates"
              />
            </div>
            <div>
              <DateRange
                name="endDate.filter"
                control={control}
                label="End date"
                onChange={(v) => hideNullsWhenSet("endDate", v)}
              />
              <Checkbox
                label=""
                name="endDate.hideNulls"
                control={control}
                fieldLabel="Hide frameworks without end dates"
              />
            </div>
          </div>
          <Divider />
          {hiddenSections.includes("suppliers") ? null : (
            <>
              <h3 className={css.sectionTitle}>Suppliers</h3>
              <div className={css.filterSection}>
                {enableNewSupplierFilter ? (
                  <NewSupplierSelect name="supplierIds" label="Supplier Guids" control={control} />
                ) : (
                  <SupplierSelect
                    name="supplierIds"
                    label="Suppliers"
                    control={control}
                    mode="multiple"
                    placeholder="Search suppliers..."
                    allowClear
                    idType="guid"
                  />
                )}
              </div>
              <Divider />
            </>
          )}
          {hiddenSections.includes("buyers") ? null : (
            <>
              <h3 className={css.sectionTitle}>Buyers</h3>
              <div className={css.filterSection}>
                <SelectBuyerList
                  name="buyerListIds"
                  label="Buyer lists"
                  control={control}
                  placeholder="Select..."
                  mode="multiple"
                  allowClear
                />
                <BuyerSelect
                  name="buyerIds"
                  label="Buyers"
                  control={control}
                  mode="multiple"
                  placeholder="Search buyers..."
                  allowClear
                />
                <BuyerTypeSelect
                  name="buyerCategories"
                  control={control}
                  multiple
                  label="Buyer type"
                  placeholder="Select..."
                  allowClear
                />
                <CountryRegionSelect
                  name="buyerCountryRegions"
                  control={control}
                  label="Buyer location"
                  placeholder="Select..."
                  allowClear
                />
                <Radio
                  label="Filter by"
                  name={"buyerParticipantType"}
                  control={control}
                  disabled={!filters.buyerIds}
                  defaultValue={""}
                  options={BUYER_PARTICIPANT_OPTIONS}
                  optionType="default"
                  direction="vertical"
                />
              </div>
            </>
          )}
        </>
      )}
    </form>
  );
}
