import React, { ReactNode } from "react";
import { FieldValues, useController, UseControllerProps } from "react-hook-form";
import {
  Checkbox as AntCheckbox,
  DatePicker as AntDatePicker,
  DatePickerProps as AntDatePickerProps,
  Input as AntInput,
  Radio as AntRadio,
  Select as AntSelect,
  Space,
  Tooltip,
  TreeSelect as AntTreeSelect,
} from "antd5";
import { CheckboxProps as AntCheckboxProps } from "antd5/lib/checkbox";
import { InputProps as AntInputProps, TextAreaProps as AntTextArea } from "antd5/lib/input";
import { DefaultOptionType, SelectProps as AntSelectProps } from "antd5/lib/select";
import { TreeSelectProps as AntTreeSelectProps } from "antd5/lib/tree-select";
import { isArray } from "chart.js/helpers";
import dayjs from "dayjs";

import { isoStringToDate } from "components/record_search/types";
import DateRangePicker from "./DateRangePicker";

import css from "./Inputs.module.scss";

type InputType<T> = {
  label: string;
  subLabel?: string;
  onChange?: (val: T) => void;
  tooltipText?: string;
};

export type Props<T extends FieldValues> = UseControllerProps<T> & InputType<T>;

type InputProps<T extends FieldValues> = Props<T> & AntInputProps;

export function Input<T extends FieldValues>(props: InputProps<T>) {
  const { label, subLabel, tooltipText, ...inputProps } = props;
  const { field, fieldState } = useController(props);

  return (
    <div>
      <label htmlFor={props.name} className={css.label}>
        <h3>{label}</h3>
        {subLabel && <span className={css.subLabel}>{subLabel}</span>}
      </label>
      <Tooltip title={tooltipText || ""} placement="top" overlayStyle={{ maxWidth: "500px" }}>
        <AntInput
          {...inputProps}
          {...field}
          id={props.name}
          status={fieldState.error && "error"}
          onChange={(val) => {
            props.onChange?.(val.target.value as unknown as T);
            field.onChange(val);
          }}
        />
      </Tooltip>
      {fieldState.error?.message && (
        <span className={css.errorMessage}>{fieldState.error.message}</span>
      )}
    </div>
  );
}

type TextAreaProps<T extends FieldValues> = Props<T> & AntTextArea;

export function TextArea<T extends FieldValues>(props: TextAreaProps<T>) {
  const { label, subLabel, tooltipText, ...inputProps } = props;
  const { field, fieldState } = useController(props);

  return (
    <div className={props.className}>
      <label htmlFor={props.name} className={css.label}>
        <h3>{label}</h3>
        {subLabel && <span className={css.subLabel}>{subLabel}</span>}
      </label>
      <Tooltip title={tooltipText || ""} placement="top" overlayStyle={{ maxWidth: "500px" }}>
        <AntInput.TextArea
          {...inputProps}
          {...field}
          id={props.name}
          status={fieldState.error && "error"}
          onChange={(val) => {
            props.onChange?.(val.target.value as unknown as T);
            field.onChange(val);
          }}
        />
      </Tooltip>
      {fieldState.error?.message && (
        <span className={css.errorMessage}>{fieldState.error.message}</span>
      )}
    </div>
  );
}

export type SelectProps<T extends FieldValues> = Props<T> &
  AntSelectProps & { options: DefaultOptionType[]; selectAll?: boolean };

export function Select<T extends FieldValues>(props: SelectProps<T>) {
  const { field, fieldState } = useController(props);
  const { label, subLabel, selectAll, ...inputProps } = props;

  let options = props.options;
  if (selectAll && props.options.length > 0) {
    // check if we have all the options selected already
    if (
      !field.value ||
      !props.options.every((option) => field.value.find((v: unknown) => v === option.value))
    ) {
      const optionswithSelectAll: DefaultOptionType[] = [
        {
          label: <span className={css.addAllSelectOption}>Add {options.length} matching</span>,
          value: "ALL",
        },
        ...props.options,
      ];
      options = optionswithSelectAll;
    }
  }

  return (
    <div className={css.flexColumn}>
      <label htmlFor={props.name} className={css.label}>
        <h3>{label}</h3>
        {subLabel && <span className={css.subLabel}>{subLabel}</span>}
      </label>
      <AntSelect
        id={props.name}
        {...inputProps}
        {...field}
        status={fieldState.error && "error"}
        onChange={(val) => {
          let value = val;
          if (selectAll && isArray(val) && val.includes("ALL")) {
            value = [...val.filter((v) => v !== "ALL")];
            const optionValues = props.options.map((option) => option.value);
            value = [...value, ...optionValues];
            value = [...new Set(value)];
          }
          props.onChange?.(value as unknown as T);
          field.onChange(value);
        }}
        options={options}
      />
      {fieldState.error?.message && (
        <span className={css.errorMessage}>{fieldState.error.message}</span>
      )}
    </div>
  );
}

export type TreeSelectProps<T extends FieldValues> = Props<T> &
  AntTreeSelectProps & { treeData: AntTreeSelectProps["treeData"] };

export function TreeSelect<T extends FieldValues>(props: TreeSelectProps<T>) {
  const { field, fieldState } = useController(props);
  const { label, subLabel, ...inputProps } = props;

  return (
    <div className={css.flexColumn}>
      <label htmlFor={props.name} className={css.label}>
        <h3>{label}</h3>
        {subLabel && <span className={css.subLabel}>{subLabel}</span>}
      </label>
      <AntTreeSelect
        {...inputProps}
        {...field}
        id={props.name}
        // only set the value if we have data otherwise it will error
        // value={props.treeData?.length ? field.value : []}
        value={props.treeData && props.treeData?.length > 0 ? field.value : []}
        status={fieldState.error && "error"}
        onChange={(val) => {
          props.onChange?.(val as unknown as T);
          field.onChange(val);
        }}
        treeData={props.treeData}
      />
      {fieldState.error?.message && (
        <span className={css.errorMessage}>{fieldState.error.message}</span>
      )}
    </div>
  );
}

type RadioProps<T extends FieldValues> = Props<T> & {
  options: { label: ReactNode; value: string | number | boolean }[];
  optionType?: "default" | "button";
  direction?: "vertical" | "horizontal";
  disabled?: boolean;
  className?: string;
};

export function Radio<T extends FieldValues>(props: RadioProps<T>) {
  const { field, fieldState } = useController(props);

  return (
    <div className={css.flexColumn}>
      <label htmlFor={props.name} className={css.label}>
        <h3>{props.label}</h3>
        {props.subLabel && <span className={css.subLabel}>{props.subLabel}</span>}
      </label>
      <AntRadio.Group
        {...field}
        optionType={props.optionType || "button"}
        onChange={(val) => {
          props.onChange?.(val.target.value as unknown as T);
          field.onChange(val);
        }}
      >
        <Space.Compact block direction={props.direction || "horizontal"} className={css.space}>
          {props.options.map((option, index) => (
            <AntRadio
              value={option.value}
              key={index}
              disabled={props.disabled}
              className={props.className}
            >
              {option.label}
            </AntRadio>
          ))}
        </Space.Compact>
      </AntRadio.Group>
      {fieldState.error?.message && (
        <span className={css.errorMessage}>{fieldState.error.message}</span>
      )}
    </div>
  );
}

type CheckboxProps<T extends FieldValues> = Props<T> &
  AntCheckboxProps & {
    fieldLabel: string;
  };

export function Checkbox<T extends FieldValues>(props: CheckboxProps<T>) {
  const { field, fieldState } = useController(props);

  return (
    <div className={css.flexColumn}>
      <label htmlFor={props.name} className={css.label}>
        <h3>{props.label}</h3>
        {props.subLabel && <span className={css.subLabel}>{props.subLabel}</span>}
      </label>
      <AntCheckbox
        {...field}
        id={props.name}
        checked={field.value}
        onChange={(val) => {
          props.onChange?.(val.target.value as unknown as T);
          field.onChange(val);
        }}
      >
        {props.fieldLabel}
      </AntCheckbox>
      {fieldState.error?.message && (
        <span className={css.errorMessage}>{fieldState.error.message}</span>
      )}
    </div>
  );
}

type DatePickerProps<T extends FieldValues> = AntDatePickerProps &
  Props<T> & {
    showTime?: boolean;
  };
export function DatePicker<T extends FieldValues>(props: DatePickerProps<T>) {
  const { field, fieldState } = useController(props);
  return (
    <div className={css.flexColumn}>
      <label htmlFor={props.name} className={css.label}>
        <h3>{props.label}</h3>
        {props.subLabel && <span className={css.subLabel}>{props.subLabel}</span>}
      </label>
      <AntDatePicker
        showTime={props.showTime}
        {...field}
        id={props.name}
        value={field.value ? dayjs(field.value) : null}
        onChange={(val) => {
          props.onChange?.(val?.toDate() as unknown as T);
          field.onChange(val?.toDate());
        }}
        disabled={props.disabled}
        disabledDate={props.disabledDate}
      />
      {fieldState.error?.message && (
        <span className={css.errorMessage}>{fieldState.error.message}</span>
      )}
    </div>
  );
}

type DateFilterProps<T extends FieldValues> = Props<T> & {
  onChange?: (val: DateFilter) => void;
  showFutureDates?: boolean;
};

export type DateFilter = {
  from?: string;
  relativeFrom?: string;
  to?: string;
  relativeTo?: string;
} & FieldValues;
export function DateRange<T extends FieldValues>(props: DateFilterProps<T>) {
  const { field, fieldState } = useController(props);

  const value: DateFilter = { ...field.value };
  const fieldWihoutRef = { ...field, ref: undefined };
  return (
    <div className={css.flexColumn}>
      <label htmlFor={props.name} className={css.label}>
        <h3>{props.label}</h3>
        {props.subLabel && <span className={css.subLabel}>{props.subLabel}</span>}
      </label>
      <DateRangePicker
        {...fieldWihoutRef}
        values={{
          ...value,
          from: isoStringToDate(value?.from),
          to: isoStringToDate(value?.to),
        }}
        handleChange={(dates) => {
          props.onChange?.({
            from: dates.relativeFrom
              ? dates.from?.toISOString()
              : dates.from?.startOf("day").toISOString(),
            to: dates.to?.toISOString(),
            relativeTo: dates.relativeTo,
            relativeFrom: dates.relativeFrom,
          } as unknown as T);
          field.onChange({
            from: dates.relativeFrom
              ? dates.from?.toISOString()
              : dates.from?.startOf("day").toISOString(),
            to: dates.to?.toISOString(),
            relativeTo: dates.relativeTo,
            relativeFrom: dates.relativeFrom,
          });
        }}
        showFutureDates={props.showFutureDates}
      />
      {fieldState.error?.message && (
        <span className={css.errorMessage}>{fieldState.error.message}</span>
      )}
    </div>
  );
}
