/**
 * NOTE: This page is similar to the admin page but might be accessed by non-admin users.
 */
import React from "react";
import { hot } from "react-hot-loader/root";
import { useVariableValue } from "@devcycle/react-client-sdk";
import { Button } from "antd"; // maybe make button container a grid with ap rather than using spaced buttons

import { withAppLayout } from "components/app_layout/AppLayout";
import { FilterableDocuments } from "components/documents/FilterableDocuments";
import { ALL_DOCUMENT_COLUMNS, DocumentColumns } from "components/documents/utils";
import { assertCurrentUser } from "lib/currentUser";
import { EditDocument } from "lib/documents/EditDocument";
import PreviousUploadsTable from "lib/documents/PreviousUploadsTable";
import { UploadDocuments } from "lib/documents/UploadDocuments";
import { DOCUMENT_TOOLING } from "lib/featureFlags";
import { useDocuments } from "lib/hooks/api/admin/useDocuments";
import { ColumnSetting } from "lib/types/models";

import css from "./DocumentUploadPage.module.scss";

const columns: ColumnSetting<DocumentColumns>[] = [
  ...ALL_DOCUMENT_COLUMNS,
  { title: "User email", field: "userEmail" },
  { title: "Upload date", field: "createdAt" },
  { title: "Actions", field: "actions" },
];

export enum PageState {
  HOME,
  UPLOAD,
  PREVIOUS_UPLOADS,
  EDIT_FILE,
}

function UploadDocumentPage(): JSX.Element {
  const [pageState, setPageState] = React.useState<PageState>(PageState.HOME);
  const [selectedDocumentId, setSelectedDocumentId] = React.useState<string>();

  const isNewToolingEnabled = useVariableValue(DOCUMENT_TOOLING, false);

  const handleGoBack = () => {
    setPageState(PageState.HOME);
  };

  const handleDocumentClick = (documentId: string) => {
    setPageState(PageState.EDIT_FILE);
    setSelectedDocumentId(documentId);
  };

  const renderPage = () => {
    switch (pageState) {
      case PageState.UPLOAD:
        return <UploadDocuments onFinished={handleGoBack} userId={assertCurrentUser().guid} />;
      case PageState.EDIT_FILE:
        return (
          <EditDocument
            onFinished={handleGoBack}
            userId={assertCurrentUser().guid}
            documentId={selectedDocumentId}
          />
        );
      case PageState.PREVIOUS_UPLOADS:
        return isNewToolingEnabled ? (
          <FilterableDocuments
            defaultColumns={columns}
            documentProvider={useDocuments}
            disablePreview
          />
        ) : (
          <PreviousUploadsTable
            userId={assertCurrentUser().guid}
            onDocumentClick={handleDocumentClick}
          />
        );
      default:
        return (
          <div className={css.buttonContainer}>
            <Button className={css.spacedButton} onClick={() => setPageState(PageState.UPLOAD)}>
              {" "}
              Upload documents
            </Button>
            <Button
              className={css.spacedButton}
              onClick={() => setPageState(PageState.PREVIOUS_UPLOADS)}
            >
              See previous uploads
            </Button>
          </div>
        );
    }
  };

  return (
    <div className={css.pageContainer}>
      {pageState !== PageState.HOME && (
        <Button onClick={handleGoBack} className={css.backButton}>
          Go Back
        </Button>
      )}
      {renderPage()}
    </div>
  );
}

export default hot(
  withAppLayout(UploadDocumentPage, {
    pageName: "Upload Document",
  }),
);
