import React, { useState } from "react";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useVariableValue } from "@devcycle/react-client-sdk";
import { Button, Input, message, Tooltip } from "antd"; // upgrade message to use context
import { UploadFile } from "antd/lib/upload/interface";
import moment from "moment";

import { DOCUMENT_TOOLING } from "lib/featureFlags";
import { useOpenApi } from "lib/openApiContext";
import { OwnerType } from "lib/StotlesApi";
import { useStotlesApi } from "lib/stotlesApiContext";
import { getCSRFToken } from "lib/utils";
import {
  DocumentBuyerSelect,
  DocumentTypeSelect,
  DocumentTypeSelectNew,
} from "./DocumentComponents";
import { UploadDocument } from "./UploadDocument";

import css from "./UploadDocuments.module.scss";

export const linkRegex =
  /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i;

type UploadDocumentsProps = {
  userId: string;
  onFinished: () => void;
};

export type FileData = {
  uploadedFile: UploadFile | undefined;
  fileLink: string | undefined;
  publishDate: moment.Moment | undefined;
  description: string | undefined;
};

const defaultFile: FileData = {
  uploadedFile: undefined,
  fileLink: undefined,
  description: undefined,
  publishDate: undefined,
};

export function getMonthStartString(date: moment.Moment | undefined | null): string | undefined {
  return date ? date.startOf("month").format("YYYY-MM-DD") : undefined;
}

export function getDateFromString(dateString: string | undefined): moment.Moment | undefined {
  return dateString ? moment(dateString, "YYYY-MM-DD") : undefined;
}

export function validateFileProperties(
  ownerGuid: string | undefined,
  sourceUrl: string | undefined,
): string[] {
  const errors = [];
  if (!ownerGuid) {
    errors.push("Please enter a valid buyer");
  }

  if (!sourceUrl || !linkRegex.test(sourceUrl)) {
    errors.push("Please enter a valid source link");
  }

  return errors;
}

export function UploadDocuments({ userId, onFinished }: UploadDocumentsProps): JSX.Element {
  const api = useStotlesApi();
  const openApi = useOpenApi();

  const [files, setFiles] = useState<FileData[]>([defaultFile]);
  const [errors, setErrors] = useState<string[]>();

  const [uploading, setUploading] = React.useState<boolean>(false);

  //shared fields for all documents
  const [ownerGuid, setOwnerGuid] = React.useState<string>();
  // TODO allow for other document types
  const [ownerType] = React.useState<OwnerType>("BUYER");
  const [sourceUrl, setSourceUrl] = React.useState<string>();
  const [documentType, setDocumentType] = React.useState<string>();

  const isNewToolingEnabled = useVariableValue(DOCUMENT_TOOLING, false);

  const handleSelectChange = (value: string) => {
    setDocumentType(value);
  };

  const handleFormSubmit = async () => {
    const errors = validateFileProperties(ownerGuid, sourceUrl);

    for (let i = 0; i < files.length; i++) {
      const displayFileNumber = i + 1;

      if (!files[i].fileLink && !files[i].uploadedFile) {
        errors.push(
          `File ${displayFileNumber} does not contain a file link or an uploaded file. Please add one`,
        );
      } else if (files[i].fileLink && files[i].uploadedFile) {
        errors.push(
          `File ${displayFileNumber} contains both a file link and an uploaded file. Please delete one`,
        );
      }
      const fileLink = files[i].fileLink;
      if (fileLink && !linkRegex.test(fileLink)) {
        errors.push(`File ${displayFileNumber} invalid link`);
      }
    }

    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    setUploading(true);
    const uploadErrors: string[] = [];
    for (let i = 0; i < files.length; i++) {
      let errorMessage;
      const displayFileNumber = i + 1;
      if (files[i].fileLink) {
        errorMessage = await uploadFileLink(displayFileNumber, files[i]);
      } else {
        errorMessage = await uploadUserFile(displayFileNumber, files[i]);
      }

      if (errorMessage) {
        uploadErrors.push(errorMessage);
      }
    }

    if (uploadErrors.length > 0) {
      void message.info("Error. There was a problem uploading the documents");
      setErrors(uploadErrors);
    } else {
      void message.info("Upload complete");
      onFinished();
    }
    setUploading(false);
  };

  const uploadUserFile = async (fileNumber: number, fileData: FileData) => {
    if (
      !ownerGuid ||
      !ownerType ||
      !sourceUrl ||
      !fileData.uploadedFile ||
      !fileData.uploadedFile.originFileObj
    ) {
      return `Error. Cannot upload file ${fileNumber}.`;
    }

    const formData = new FormData();
    formData.append("uploaded_by", `user:${userId}`);
    formData.append("file", fileData.uploadedFile.originFileObj, fileData.uploadedFile.fileName);
    formData.append("owner_guid", ownerGuid);
    formData.append("owner_type", ownerType);
    formData.append("source_url", sourceUrl);

    //TODO: remove the old form data fields above
    formData.append("createdBy", userId);
    formData.append("organisations[]", ownerGuid);
    formData.append("sourceUrl", sourceUrl);

    if (fileData.description && fileData.description.length > 0) {
      formData.append("description", fileData.description);
    }

    if (documentType) {
      formData.append("document_type", documentType);
      // TODO: Make this required in the UI
      formData.append("category", documentType);
    }

    const date = getMonthStartString(fileData.publishDate);
    if (date) {
      formData.append("published_at", date);
      formData.append("publishedAt", date);
    }

    const headers = {
      "X-CSRF-Token": getCSRFToken() ?? "invalid",
    };
    // ideally this should move into the StotlesApi code
    // but since that forces a JSON body (or file) we need to submit manually
    try {
      if (isNewToolingEnabled) {
        const response = await fetch("/api/docs/file-upload", {
          method: "POST",
          body: formData,
          headers: headers,
        });

        if (!response.ok) {
          throw new Error("Error uploading file");
        }
      } else {
        const response = await fetch("/api/documents/uploadFile", {
          method: "POST",
          body: formData,
          headers: headers,
        });

        if (!response.ok) {
          throw new Error("Error uploading file");
        }
      }

      void message.info(`Upload ${fileNumber} complete`);
    } catch (error) {
      console.log(error);
      void message.error("Unable to get documents; please contact us if the problem persists");
      return `Error uploading file document ${fileNumber}`;
    }
  };

  const uploadFileLink = async (fileNumber: number, fileData: FileData) => {
    if (!ownerGuid || !ownerType || !fileData.fileLink) {
      return `Error. Cannot upload file ${fileNumber}.`;
    }

    try {
      if (isNewToolingEnabled) {
        await openApi.uploadUrlDocument({
          body: {
            sourceUrl: sourceUrl,
            organisations: [ownerGuid],
            category: documentType,
            publishedAt: getMonthStartString(fileData.publishDate),
            createdBy: userId,
            description: fileData.description,
            url: fileData.fileLink,
          },
        });
      } else {
        await api.uploadLinkedFile(
          `user:${userId}`,
          ownerGuid,
          ownerType,
          fileData.fileLink,
          documentType,
          fileData.description,
          sourceUrl,
          getMonthStartString(fileData.publishDate),
        );
      }
      void message.info(`Upload ${fileNumber} complete`);
    } catch (error) {
      console.log(error);
      void message.error(`Unable to upload document; please contact us if the problem persists`);
      return `Error uploading link document ${fileNumber}`;
    }
  };

  const handleFileDataUpdate = (fileData: FileData | undefined, fileNumber: number) => {
    if (fileData) {
      const newFilesList = [...files];
      newFilesList[fileNumber] = fileData;
      setFiles(newFilesList);
    } else {
      const newFilesList = files.slice();
      newFilesList.splice(fileNumber, 1);
      setFiles(newFilesList);
    }
  };

  const handleAddFile = () => {
    const newFilesList = files.slice();
    newFilesList.push(defaultFile);
    setFiles(newFilesList);
  };

  return (
    <div className={css.documentForm}>
      <div className={css.field}>
        <span className={css.required}>*</span>
        <span>Select Buyer</span>
        <DocumentBuyerSelect onBuyerChange={(buyerGuid) => setOwnerGuid(buyerGuid)} />
      </div>
      <div className={css.field}>
        <span className={css.required}>*</span>
        <span>
          Source URL
          <Tooltip title="Location where these documents where found?">
            <QuestionCircleOutlined />
          </Tooltip>
        </span>

        <Input onChange={(e) => setSourceUrl(e.currentTarget.value)} />
      </div>
      <div className={css.field}>
        <label className={css.label}>Document Type</label>
      </div>
      {isNewToolingEnabled ? (
        <DocumentTypeSelectNew onChange={handleSelectChange} />
      ) : (
        <DocumentTypeSelect onChange={handleSelectChange} />
      )}
      {files?.map((fileData, i) => (
        <UploadDocument
          key={i}
          fileData={fileData}
          fileNumber={i}
          onFileDataUpdate={handleFileDataUpdate}
        />
      ))}
      <Button type="primary" onClick={handleAddFile}>
        Add another file
      </Button>
      <div className={css.errors}>
        {errors?.map((err, i) => (
          <span key={i} className={css.error}>
            {err}
          </span>
        ))}
      </div>
      <Button type="primary" loading={uploading} onClick={handleFormSubmit}>
        Upload Documents
      </Button>
    </div>
  );
}
