import React from "react";
import { Alert, Select } from "antd"; //upgrade and verify

import { createUseDebounce } from "lib/debounce";
import { CompanyDto } from "lib/generated/admin-api/models/CompanyDto";
import { ResponseError } from "lib/generated/admin-api/runtime";
import { useAdminApi } from "lib/stotlesAdminApiContext";

import css from "./SwitchCompanySearch.module.scss";

type Props = {
  onChange: (companyGuid: CompanyDto) => void;
};

function SwitchCompanySearch(props: Props) {
  const [companies, setCompanies] = React.useState<CompanyDto[] | undefined>([]);
  const [selectedCompany, setSelectedCompany] = React.useState<CompanyDto>();
  const [error, setError] = React.useState<string | undefined>(undefined);
  const adminApi = useAdminApi();

  const debounce = createUseDebounce(500);

  const onSearch = async (event: string) => {
    try {
      const { companies } = await adminApi.api.searchCompanies({
        searchCompaniesRequest: { query: event },
      });
      setCompanies(companies);
    } catch (apiError) {
      if (apiError instanceof ResponseError) {
        setError(`Error ${apiError.response.status}: ${apiError.message}`);
      } else {
        setError("Unknown error occurred.");
      }
    }
  };

  const onSelect = (event: string) => {
    const companyGuid = event;
    const company = companies?.find((c) => c.guid === companyGuid);
    setSelectedCompany(company);
    company && props.onChange(company);
  };

  const debouncedOnSearch = debounce(onSearch);

  return (
    <div>
      <Select
        className={css.select}
        id="search-by-text"
        allowClear
        showSearch
        placeholder="Search for a company"
        onSearch={debouncedOnSearch}
        onChange={onSelect}
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        notFoundContent={null}
        value={selectedCompany?.guid}
      >
        {companies?.map((company: CompanyDto) => (
          <Select.Option key={company.guid} value={company.guid}>
            {company.name} {company.isDemo && "- (demo)"} - ({company.users?.length} users)
          </Select.Option>
        ))}
      </Select>
      {error && <Alert message={error} type="error" />}
    </div>
  );
}

export default SwitchCompanySearch;
