import React from "react";
import { InfoCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { TabsProps, Tooltip } from "antd5";

import { UNSUPPORTED_MIME_TYPES } from "components/documents/utils";
import { DocumentDetails as Document } from "lib/hooks/api/documents/useDocument";
import { grey600 } from "lib/themes/colors";
import { getTabsConfig } from "lib/themes/TabsConfig";
import DocumentDetails from "./DocumentDetails";
import DocumentSearch from "./DocumentSearch";
import { DocumentViewerFilters } from "./FilterForm";

export const TabsConfig = getTabsConfig({ horizontalItemPadding: 100 });

export const TAB_KEYS = { info: "info", search: "search" };

export function createTabItems(
  documentId: string,
  isLoading: boolean,
  isSearchDisabled: boolean,
  filters: DocumentViewerFilters,
  data?: Document,
): TabsProps["items"] {
  return [
    {
      key: TAB_KEYS.search,
      disabled: isSearchDisabled,
      label: isSearchDisabled ? (
        <Tooltip
          placement="bottom"
          title="Search unavailable for this document format."
          overlayInnerStyle={{
            width: "188px",
            textAlign: "center",
            padding: "12px",
            backgroundColor: grey600,
          }}
        >
          <SearchOutlined />
        </Tooltip>
      ) : (
        <SearchOutlined />
      ),
      children: <DocumentSearch defaultFilters={filters} documentId={documentId} />,
      forceRender: true,
    },
    {
      key: TAB_KEYS.info,
      label: <InfoCircleOutlined />,
      children: (
        <DocumentDetails
          id={data?.id ?? ""}
          buyers={data?.buyers ?? []}
          signalScore={data?.signalScore ?? 0}
          type={data?.category ?? ""}
          publishDate={data?.publishedAt ?? ""}
          sourceUrls={data?.sourceUrls ?? []}
          alternativeSource={data?.alternativeSource ?? ""}
          isLoading={isLoading}
        />
      ),
    },
  ];
}

export const tooltips = {
  emptyState:
    "Sorry, it looks like this isn't available yet. We're working hard to populate our documents data.",
  alternativeSource:
    "Sorry, we've collected this data from an alternative source, so there's no link available.",
};

export const isMimeTypeSupported = (mimeType: string) => !UNSUPPORTED_MIME_TYPES.includes(mimeType);
