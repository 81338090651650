import React, { useEffect, useState } from "react";
import { Core } from "@pdftron/webviewer";

import { createUseDebounce } from "lib/debounce";
import { useDocumentHighlight } from "lib/hooks/api/documents/useDocumentHighlight";
import { usePdfViewerInstance } from "../../lib/core_components/PdfViewer";
import { useURLState } from "../../lib/hooks/useURLState";
import DocumentSearchResults, { SearchResultSnippet } from "./DocumentSearchResults";
import FilterForm, { DocumentViewerFilters } from "./FilterForm";

import css from "./DocumentSearch.module.scss";

type DocumentSearchProps = {
  documentId?: string;
  defaultFilters: DocumentViewerFilters;
};

export default function DocumentSearch({ documentId, defaultFilters }: DocumentSearchProps) {
  const { instance } = usePdfViewerInstance();
  const [documentReady, setIsDocumentReady] = useState(false);

  const [isSearchInProgress, setIsSearchInProgress] = useState(false);
  const [isError, setIsError] = useState(false);
  const [searchResults, setSearchResults] = useState<SearchResultSnippet[] | null>(null);
  const [activeResultIndex, setActiveResultIndex] = useState<number>(0);

  const [filters = defaultFilters, setFilters] = useURLState<DocumentViewerFilters>(
    "docViewerFilters",
    defaultFilters,
  );

  const { data: matches, isFetching: isHighlightsLoading } = useDocumentHighlight(
    documentId ?? "",
    { keywords: filters.keywords },
    {
      enabled: !!documentId && !!filters.keywords.length,
    },
  );

  const useDebounce = createUseDebounce(500);
  const debouncedSetFilters = useDebounce((filters: DocumentViewerFilters) => {
    setFilters(filters);
  });

  const filterCount = filters.keywords.length;

  useEffect(() => {
    if (!instance) return;

    setIsError(false);
    setIsSearchInProgress(true);

    const { documentViewer, Search } = instance.Core;

    const termsToHighlight = matches?.contentHighlights;

    if (!termsToHighlight?.length && !isHighlightsLoading) {
      // clear search results if no matches found
      setSearchResults([]);
      documentViewer.clearSearchResults();
      setIsSearchInProgress(false);
    }

    if (documentReady) {
      const searchText = termsToHighlight?.length ? termsToHighlight.join("|") : "";

      let index = 0;
      const results: SearchResultSnippet[] = [];

      if (searchText) {
        documentViewer.textSearchInit(
          searchText,
          Search.Mode.PAGE_STOP |
            Search.Mode.HIGHLIGHT |
            Search.Mode.AMBIENT_STRING |
            Search.Mode.REGEX |
            Search.Mode.WHOLE_WORD,
          {
            fullSearch: true,
            onError: () => {
              setIsSearchInProgress(false);
              setIsError(true);
            },
            onResult: (result: Core.Search.SearchResult) => {
              if (result.resultCode === Search.ResultCode.FOUND) {
                results.push({ ...result, originalIndex: index });
                index++;
              }
            },
            onDocumentEnd: () => {
              setIsSearchInProgress(false);
              if (results.length) {
                documentViewer.displayAdditionalSearchResults(results);
                documentViewer.setActiveSearchResult(results[0]);
                setActiveResultIndex(0);
                setSearchResults(results);
              }
            },
          },
        );
      }
    }
  }, [documentReady, matches, instance, isHighlightsLoading, filterCount]);

  useEffect(() => {
    const documentViewer = instance?.Core.documentViewer;

    const handleIsDocumentReady = () => setIsDocumentReady(true);

    if (documentViewer) {
      // Waiting for documentLoaded event is not sufficient and gives incomplete results
      // Instead, wait for the last event of the document lifecycle
      documentViewer.addEventListener("annotationsLoaded", () => {
        handleIsDocumentReady();
      });
    }

    // Clean up event listeners
    return () => {
      if (documentViewer) {
        documentViewer.removeEventListener("annotationsLoaded", handleIsDocumentReady);
      }
    };
  }, [instance]);

  const handleJumpToSearchResult = (result: SearchResultSnippet) => {
    if (instance) {
      instance.Core.documentViewer.setActiveSearchResult(result);
    }
  };

  return (
    <>
      <div className={css.filters}>
        <h3 className={css.filtersHeader}>Search</h3>
        <FilterForm filters={filters} onChange={debouncedSetFilters} />
      </div>
      <DocumentSearchResults
        activeResultIndex={activeResultIndex}
        setActiveResultIndex={setActiveResultIndex}
        searchResults={searchResults}
        isLoading={isSearchInProgress}
        onJumpToSearchResult={handleJumpToSearchResult}
        filterCount={filters.keywords.length}
        isError={isError}
      />
    </>
  );
}
