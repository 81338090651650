import React, { useState } from "react";
import { Control, FieldValues, Path } from "react-hook-form";
import { BulbOutlined } from "@ant-design/icons";
import { Button } from "antd5";

import { sysPrimaryDefault } from "../../../../lib/themes/colors";
import { TextSearchTipsModal } from "../../../modals/TextSearchTipsModal";
import TagInput from "../../../ui/tag_input/TagInput";
import SuggestedKeywordsInput from "./suggested_keywords_input/SuggestedKeywordsInput";

import css from "./KeywordInput.module.scss";

type KeywordCollapsibleProps<T extends FieldValues> = {
  name: Path<T>;
  control: Control<T, unknown>;
};

export default function KeywordCollapsible<T extends FieldValues>({
  name,
  control,
}: KeywordCollapsibleProps<T>) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  return (
    <div className={css.container}>
      <SuggestedKeywordsInput name={name} control={control} />
      <div className={css.inputContainer}>
        <TagInput name={name} control={control} variant="active" />
      </div>
      <div className={css.buttonContainer}>
        <Button
          onClick={handleOpen}
          icon={<BulbOutlined color={sysPrimaryDefault} className={css.icon} />}
        >
          Advanced keyword tips
        </Button>
      </div>

      <TextSearchTipsModal isOpen={isModalOpen} onClose={handleClose} />
    </div>
  );
}
