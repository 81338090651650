import React from "react";
import { Card, Divider } from "antd5";
import classNames from "classnames";

import { CommentsContainer } from "components/comments/CommentsContainer";
import ContactCard from "components/form_components/ContactCard";
import { getFrontendQualification } from "components/notices/utils";
import AiRecordSummary from "components/record_summary/SummarySection";
import { TextHighlighter } from "lib/core_components/TextHighlighter";
import { NoticeDetails } from "lib/hooks/api/notices/useNotice";
import { NoticeHighlights } from "lib/hooks/api/notices/useNoticeHighlight";
import { useRecordHighlighting } from "lib/hooks/useRecordHighlighting";
import { getAddress, simpleArrayDedupe } from "lib/utils";
import { AutoQualifyCard } from "./AutoQualifyCard";
import CpvCodes from "./CpvCodes";
import DetailsCardContent from "./DetailsCardContent";
import LotDetails from "./LotDetails";
import NoticeTimeline from "./NoticeTimeline";
import WorkflowCard from "./WorkflowCard";

import css from "./RecordDetailsContent.module.scss";

type DescriptionProps = {
  noticeGuid: string;
  noticeName: string;
  noticeDescription?: string | null;
  highlights: string[];
};

function Description({ noticeGuid, noticeName, noticeDescription, highlights }: DescriptionProps) {
  const { recordDescription } = useRecordHighlighting(noticeGuid, noticeName, noticeDescription);

  const description = noticeDescription ? recordDescription : "No description available.";

  return (
    <div className={css.description}>
      <h2 className={css.section}>Description</h2>
      {noticeDescription && highlights.length > 0 ? (
        <TextHighlighter content={noticeDescription} wordsToHighlight={highlights} />
      ) : (
        <>{description}</>
      )}
    </div>
  );
}

type NoticeDetailsContentProps = {
  notice: NoticeDetails;
  noticeHighlights: NoticeHighlights | undefined;
  trackingContextName: "Title header" | "Record preview slide-out";
  containerClassName?: string;
};

export default function NoticeDetailsContent({
  notice,
  noticeHighlights,
  trackingContextName,
  containerClassName,
}: NoticeDetailsContentProps) {
  const buyerAddress = getAddress(
    notice.buyers[0].country ?? "",
    notice.buyers[0].address ?? "",
    notice.buyers[0].town ?? "",
    notice.buyers[0].postalCode ?? "",
  );

  const allHighlights = noticeHighlights
    ? simpleArrayDedupe([
        ...(noticeHighlights.title || []),
        ...(noticeHighlights.description || []),
      ])
    : [];
  return (
    <div className={classNames(containerClassName, css.container)}>
      <div className={css.leftColumn}>
        {!!notice.description && notice.description.length >= 150 && (
          <AiRecordSummary recordGuid={notice.guid} highlights={allHighlights} />
        )}
        <Description
          noticeGuid={notice.guid}
          noticeName={notice.name ?? ""}
          noticeDescription={notice.description}
          highlights={noticeHighlights?.description || []}
        />
        <Divider className={css.divider} />
        <NoticeTimeline
          procurementProcessId={notice.procurementStage.procurementProcessId}
          stage={notice.stage ?? "Unknown"}
        />
        <Divider className={css.divider} />
        <LotDetails lots={notice.lots ?? []} />
        <Divider className={css.divider} />
        <CpvCodes cpvCodes={notice.cpvCodes} />
      </div>
      <div className={css.rightColumn}>
        {!window.guestUser && (
          <>
            <AutoQualifyCard noticeId={notice.guid} />
            <WorkflowCard
              procurementStage={{
                id: notice.procurementStage.id,
                stage: notice.procurementStage.stage ?? "",
                qualification: notice.qualification
                  ? getFrontendQualification(notice.qualification)
                  : undefined,
              }}
              notice={{
                guid: notice.guid,
                name: notice.name ?? "",
                buyers: notice.buyers,
              }}
              assignee={notice.assignee ? { ...notice.assignee } : undefined}
              signals={notice.signals ?? []}
              score={notice.score ?? 0}
              trackingContextName={trackingContextName}
            />
          </>
        )}
        <Card className={css.card}>
          <div className={css.cardContent}>
            <h2 className={css.mediumTitle}>Details</h2>
            <DetailsCardContent
              notice={{
                guid: notice.guid,
                stage: notice.stage ?? "Unknown",
                value: notice.value ?? null,
                currency: notice.currency ?? "",
                url: notice.url,
              }}
              buyers={notice.buyers}
              lots={notice.lots}
              signals={notice.signals ?? []}
              score={notice.score ?? 0}
            />
          </div>
        </Card>
        <ContactCard
          name={notice.buyerContactName ?? ""}
          phone={notice.buyerContactPhone ?? ""}
          email={notice.buyerContactEmail ?? ""}
          address={buyerAddress}
          website={notice.buyers[0].buyerUrl ?? ""}
          buyerIds={notice.buyers.map((buyer) => buyer.guid)}
          showWebsite
          showContactsButton
          className={css.contactLabel}
        />
        {!window.guestUser && (
          <Card className={css.card}>
            <div className={css.cardContent}>
              <h2 className={css.smallTitle}>Comments</h2>
              <CommentsContainer
                procurementStageId={notice.procurementStage.id}
                recordGuid={notice.guid}
                containerClassName={css.commentsContainer}
              />
            </div>
          </Card>
        )}
      </div>
    </div>
  );
}
