import React, { useState } from "react";

import { CandidateItem, CandidateListPanel } from "components/organisation_clean/OrgListPanel";
import { OrgWithStats } from "components/organisation_clean/types";
import {
  SearchOrganisationsRequest,
  SearchOrganisationsRequest_PrimaryRoleEnum_0 as OrgPrimaryRole,
  SearchOrganisationsRequest_SortOrderEnum_0 as SortOrder,
} from "lib/generated/app-service-gql/graphql";
import { useSearchOrganisations } from "lib/hooks/api/organisations/useSearchOrganisations";
import { SearchOrganisationsFilters } from "./useOrgSearch";

import css from "./SelectAnchorPage.module.scss";

type Props = {
  anchorOrg?: OrgWithStats;
  onAnchorChange: (b: OrgWithStats) => void;
  orgPrimaryRole: "Buyer" | "Supplier";
};

const DEFAULT_PAGINATION = { current: 1, pageSize: 100 };
function SelectAnchorPage({ onAnchorChange, anchorOrg, orgPrimaryRole }: Props): JSX.Element {
  const [pagination] = useState(DEFAULT_PAGINATION);

  const [filters, setFilters] = useState<SearchOrganisationsRequest>({
    textSearch: anchorOrg?.name || "",
    primaryRole: OrgPrimaryRole[orgPrimaryRole],
    countryCode: "UK",
    limit: pagination.pageSize,
    page: pagination.current,
    sortOrder: SortOrder.Asc,
  });

  const { data } = useSearchOrganisations(
    {
      ...filters,
      page: pagination.current,
      limit: pagination.pageSize,
    },
    true,
    false,
  );

  const searchResults: OrgWithStats[] =
    data?.searchOrganisations.orgs.map((org) => ({
      guid: org.id as string,
      is_stotles_verified: org.isStotlesVerified as boolean,
      country: org.countryCode || "",
      // TODO: return real categories from the back end
      categories: [],
      name: org.name,
      address: org.address,
      town: org.town,
      url: org.url,
      ...(org?.noticeCount && {
        stats: {
          record_count: org?.noticeCount,
          latest_activity: "",
        },
      }),
    })) || [];

  return (
    <div className={css.selectAnchorPage}>
      <div className={css.searchPanel}>
        <header>
          <h1>Step 1. Select the anchor organisation</h1>
          <SearchOrganisationsFilters filters={filters} setFilters={setFilters} />
        </header>
        <CandidateListPanel
          orgs={searchResults}
          orgPrimaryRole={orgPrimaryRole}
          buttonConfig={{
            selectedLabel: "Selected",
            label: "Select",
            onSelect: (b) => {
              onAnchorChange(b);
            },
          }}
          disableSelected={true}
          selectedGuids={anchorOrg ? [anchorOrg.guid] : []}
        />
      </div>
      <div className={css.resultPanel}>
        <div className={css.selectedAnchorCard}>
          <h2>Selected anchor</h2>

          {!anchorOrg?.name && (
            <h3>
              <i>No anchor selected</i>{" "}
            </h3>
          )}

          {anchorOrg && <CandidateItem org={anchorOrg} />}
        </div>
      </div>
    </div>
  );
}

export default SelectAnchorPage;
