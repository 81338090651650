import React, { ChangeEvent, KeyboardEvent, useRef, useState } from "react";
import { FieldValues, useController, UseControllerProps } from "react-hook-form";
import { CloseOutlined } from "@ant-design/icons";

import Tag, { TagProps } from "../tag/Tag";

import css from "./TagInput.module.scss";

type TagInputProps<T extends FieldValues> = UseControllerProps<T> & {
  className?: string;
  placeholder?: string;
  variant?: TagProps["variant"];
};

const SEPARATORS = ["Enter", " ", ",", ";"];

export default function TagInput<T extends FieldValues>(props: TagInputProps<T>) {
  const { className, placeholder, variant, ...rest } = props;
  const { field } = useController(rest);

  const [inputValue, setInputValue] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);

  const addTag = (tag: string) => {
    const trimmedTag = tag.trim();
    if (trimmedTag && !field.value.includes(trimmedTag)) {
      field.onChange([...field.value, trimmedTag]);
    }
    setInputValue("");
  };

  const removeTag = (index: number) => {
    const newTags = [...field.value];
    newTags.splice(index, 1);
    field.onChange(newTags);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (SEPARATORS.includes(e.key)) {
      e.preventDefault();
      const newTags = inputValue
        .split(/[\s,]+/)
        .map((tag) => tag.trim())
        .filter(Boolean);
      newTags.forEach(addTag);
      setInputValue("");
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedText = e.clipboardData.getData("text");
    const tags = pastedText.split(/[\s,]+/).filter((tag) => tag.trim() !== "");
    const newTags = [...field.value, ...tags.filter((tag) => !field.value.includes(tag))];
    field.onChange(newTags);
  };

  const handleContainerClick = () => {
    inputRef.current?.focus();
  };

  return (
    <div className={`${css.tagInput} ${className || ""}`} onClick={handleContainerClick}>
      {field.value.map((tag: string, index: number) => (
        <Tag
          key={`${tag}-${index}`}
          label={tag}
          variant={variant}
          icon={<CloseOutlined className={css.closeIcon} />}
          onClick={() => removeTag(index)}
        />
      ))}
      <div className={css.inputWrapper}>
        <input
          ref={inputRef}
          className={css.input}
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onPaste={handlePaste}
          placeholder={field.value.length === 0 ? placeholder : ""}
        />
        <span className={css.hiddenText}>{inputValue || placeholder}</span>
      </div>
    </div>
  );
}
