import { useMutation, UseMutationOptions, useQueryClient } from "@tanstack/react-query";

import { useOpenApi } from "lib/openApiContext";

type Params = {
  id: string;
  category: string;
  publishDate: string;
  description: string;
  sourceUrls: { id: string; sourceUrl: string }[];
  alternativeSource: string;
  buyers: string[];
};

export function useUpdateDocument(options?: UseMutationOptions<void, unknown, Params, unknown>) {
  const api = useOpenApi();
  const queryClient = useQueryClient();
  return useMutation(
    ({ id, category, publishDate, description, alternativeSource, buyers, sourceUrls }: Params) =>
      api.updateDocument({
        body: { id, category, publishDate, description, alternativeSource, buyers, sourceUrls },
      }),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        void queryClient.invalidateQueries(["documentsAdmin"]);
        void queryClient.invalidateQueries(["documents"]);
        options?.onSuccess && options?.onSuccess(data, variables, context);
      },
    },
  );
}
