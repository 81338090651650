import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import {
  FrameworkHighlightRequest,
  HighlightFrameworkQuery,
} from "../../../generated/app-service-gql/graphql";
import { useGraphQlQuery } from "../useGraphQlClient";
import { REACT_QUERY_OPTIONS_NEVER_REFETCH } from "../utils";

const highlightFrameworkQuery = graphql(`
  query highlightFramework($request: FrameworkHighlightRequest!) {
    highlightFramework(FrameworkHighlightRequest: $request) {
      title
      description
      lotTitle
      lotDescription
    }
  }
`);

export type FrameworkHighlightResponse = HighlightFrameworkQuery["highlightFramework"];

export function useFrameworkHighlight(
  req: FrameworkHighlightRequest,
  options?: UseQueryOptions<HighlightFrameworkQuery, unknown, HighlightFrameworkQuery, QueryKey>,
) {
  const { data, ...rest } = useGraphQlQuery(
    ["frameworkHighlight", req],
    highlightFrameworkQuery,
    [{ request: req }],
    {
      ...options,
      ...REACT_QUERY_OPTIONS_NEVER_REFETCH,
    },
  );

  return { data: data?.highlightFramework, ...rest };
}
