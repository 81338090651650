import React from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { Button, message, Modal } from "antd5";
import { RangePickerProps } from "antd5/es/date-picker";
import dayjs from "dayjs";

import { BuyerSelect } from "components/form_components/BuyerSelect";
import { DatePicker, Input, Select } from "components/form_components/Inputs";
import { linkRegex } from "lib/documents/UploadDocuments";
import { useDocument } from "lib/hooks/api/admin/useDocument";
import { useUpdateDocument } from "lib/hooks/api/admin/useUpdateDocument";
import { DocumentsSearchResponse } from "lib/hooks/api/documents/useDocuments";
import { isDefined } from "lib/utils";
import { documentTypes } from "./utils";

import css from "./EditDocumentModal.module.scss";

type DocumentResult = DocumentsSearchResponse["results"][0];

type EditDocumentFormFields = {
  description: string;
  publishDate: string;
  category: string;
  sourceUrls: { id: string; sourceUrl: string | null }[];
  alternativeSource: string;
  buyers: string[];
};

type EditDocumentModalProps = {
  selectedDocument: DocumentResult;
  isOpen: boolean;
  onClose: () => void;
};

export function EditDocumentModal({ selectedDocument, isOpen, onClose }: EditDocumentModalProps) {
  const { data, isLoading } = useDocument(selectedDocument.id);

  const sourceLinks = React.useMemo(
    () => data?.sourceUrlLinks?.filter((link) => isDefined(link.sourceUrl)) ?? [],
    [data?.sourceUrlLinks],
  );
  const { mutate: updateDocument, isLoading: isUpdateDocumentLoading } = useUpdateDocument({
    onSuccess: () => message.success("Document updated successfully!"),
    onError: () => message.error("Sorry, an error has occurred while updating this document"),
  });

  const defaultValues = {
    description: data?.description || "",
    category: data?.category || "",
    publishDate: data?.publishedAt || "",
    sourceUrls: sourceLinks,
    buyers: data?.buyers?.map((buyer) => buyer.id) || [],
  };

  const { control, handleSubmit, reset } = useForm<EditDocumentFormFields>({
    mode: "onSubmit",
    defaultValues: defaultValues,
  });

  React.useEffect(() => {
    if (data) {
      reset({
        description: data.description || "",
        category: data.category || "",
        publishDate: data.publishedAt || "",
        sourceUrls: sourceLinks,
        buyers: data.buyers?.map((buyer) => buyer.id) || [],
      });
    }
  }, [data, reset, sourceLinks]);

  const handleOnClose = () => {
    onClose();
    reset(defaultValues);
  };

  const { fields } = useFieldArray<EditDocumentFormFields>({
    control,
    name: "sourceUrls",
  });

  const disabledFutureDate: RangePickerProps["disabledDate"] = (current) => {
    return current && current > dayjs().endOf("day");
  };

  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      title={`Edit ${selectedDocument?.title}`}
      okText="Update document"
      footer={null}
      className={css.modal}
    >
      <form
        className={css.form}
        onSubmit={handleSubmit((formInput) => {
          updateDocument({
            ...formInput,
            id: selectedDocument?.id ?? "",
            sourceUrls: formInput.sourceUrls.map((url) => ({
              id: url.id,
              sourceUrl: url.sourceUrl ?? "",
            })),
          });
          handleOnClose();
        })}
      >
        <BuyerSelect
          name="buyers"
          label="Buyer(s)"
          control={control}
          mode="multiple"
          placeholder="Select buyers"
          allowClear
          rules={{ required: { value: true, message: "Buyer is required" } }}
        />
        {fields.map((field, index) => (
          <div key={field.id}>
            <Input
              control={control}
              name={`sourceUrls.${index}.sourceUrl`}
              label={`Source URL ${fields.length > 1 ? index + 1 : ""}`}
              rules={{
                required: { value: true, message: "Source URL is required" },
                pattern: {
                  value: linkRegex,
                  message: "Please enter a valid URL",
                },
              }}
            />
          </div>
        ))}
        <Select
          name="category"
          control={control}
          label="Document type"
          optionFilterProp="label"
          options={documentTypes}
          placeholder="Select document types"
          loading={isLoading}
          rules={{ required: { value: true, message: "Category is required" } }}
        />
        <DatePicker
          name="publishDate"
          label="Publish date"
          control={control}
          disabledDate={disabledFutureDate}
          rules={{ required: { value: true, message: "Publish date is required" } }}
        />
        <Input name="description" label="Description" control={control} />
        <div className={css.actions}>
          <Button onClick={() => handleOnClose()}>Cancel</Button>
          <Button htmlType="submit" loading={isUpdateDocumentLoading} type="primary">
            Update document
          </Button>
        </div>
      </form>
    </Modal>
  );
}
