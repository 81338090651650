import React from "react";
import { Button, Select } from "antd"; // upgrade and verify

import { CompanyDto } from "lib/generated/admin-api/models/CompanyDto";

import css from "./SwitchCompanyRecents.module.scss";

type Props = {
  companies: CompanyDto[];
  onChange: (companyGuid: string) => void;
  clearButton: () => void;
};

function SwitchCompanyRecents(props: Props) {
  return (
    <div>
      <Select className={css.select} onChange={props.onChange}>
        {props.companies.map((company) => {
          return (
            <Select.Option key={company.guid} value={company.guid}>
              {company.name}
            </Select.Option>
          );
        })}
      </Select>
      <Button style={{ display: "block", marginTop: "10px" }} onClick={props.clearButton}>
        Clear Recents
      </Button>
    </div>
  );
}

export default SwitchCompanyRecents;
