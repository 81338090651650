import { z } from "zod";

import { OptionItem } from "../data/optionItems";
import { SignalSettings } from "../hooks/api/teams/useSignalSettingsGQL";

const signalSettingsErrorMessages = {
  countries: "Please select at least one country",
  languages: "Please select at least one language",
};

export const createSignalSettingsSchema = (internationalDataAccess: boolean) =>
  z.object({
    countries: z.array(z.string()).min(1, { message: signalSettingsErrorMessages.countries }),
    languages: z
      .array(z.string())
      // If international data access is false,
      // then the languages field is required
      .refine((languages) => !internationalDataAccess || languages.length > 0, {
        message: signalSettingsErrorMessages.languages,
      }),
    keywords: z.array(z.string()).optional(),
    competitors: z.array(z.string()).optional(),
    partners: z.array(z.string()).optional(),
    cpvCodes: z.array(z.string()).optional(),
  });

export type SignalSettingsForm = {
  countries: string[];
  languages: string[];
  keywords: string[];
  competitors: string[];
  partners: string[];
  cpvCodes: string[];
};

export const defaultSignalSettings: SignalSettingsForm = {
  countries: [],
  languages: [],
  keywords: [],
  competitors: [],
  partners: [],
  cpvCodes: [],
};

export const getDefaultSignalSettings = (signals: SignalSettings): SignalSettingsForm => {
  return {
    countries: signals.countries ?? [],
    languages: signals.languages ?? [],
    keywords: signals.keywords ?? [],
    competitors: signals.competitors?.map((competitor) => competitor.id) ?? [],
    partners: signals.partners?.map((partner) => partner.id) ?? [],
    cpvCodes: signals.cpvCodes ?? [],
  };
};

export function parseSignalSettings({
  countries,
  languages,
  keywords,
  competitors,
  partners,
  cpvCodes,
}: {
  countries: string[];
  languages: string[];
  keywords: string[];
  competitors: { toString: () => string }[];
  partners: { toString: () => string }[];
  cpvCodes: string[];
}) {
  return {
    input: {
      countries,
      languages,
      keywords,
      competitorIds: competitors.map((competitor) => competitor.toString()),
      partnerIds: partners.map((partner) => partner.toString()),
      cpvCodes: cpvCodes,
    },
  };
}

const mapOptionItemsToSelectOptions = (optionItems: OptionItem[]) => {
  return optionItems.map((optionItem) => ({
    id: optionItem.code,
    label: optionItem.name,
    value: optionItem.name,
  }));
};

const sortCountries = (a: OptionItem, b: OptionItem) => {
  const priorityOrder = ["UK", "IE"];
  const indexA = priorityOrder.indexOf(a.code);
  const indexB = priorityOrder.indexOf(b.code);

  if (indexA !== -1 && indexB !== -1) return indexA - indexB;
  if (indexA !== -1) return -1;
  if (indexB !== -1) return 1;
  return a.name.localeCompare(b.name);
};

const sortLanguages = (a: OptionItem, b: OptionItem) => {
  if (a.code === "EN") return -1;
  if (b.code === "EN") return 1;
  return a.name.localeCompare(b.name);
};

export const generateCountryOptions = (countryCodes: OptionItem[]) => {
  return mapOptionItemsToSelectOptions(countryCodes.sort(sortCountries));
};

export const generateLanguageOptions = (langCodes: OptionItem[]) => {
  return mapOptionItemsToSelectOptions(langCodes.sort(sortLanguages));
};
