import { QueryKey, UseQueryOptions } from "@tanstack/react-query";

import { graphql } from "lib/generated/app-service-gql";
import { DocumentQuery } from "../../../generated/app-service-gql/graphql";
import { useGraphQlQuery } from "../useGraphQlClient";

const documentQuery = graphql(`
  query document($id: String!) {
    document(documentId: $id) {
      id
      title
      category
      publishedAt
      signalScore
      pdfDocumentUrl
      downloadUrl
      sourceUrls
      alternativeSource
      originalMimeType
      buyers {
        id
        name
      }
    }
  }
`);

export type DocumentDetails = DocumentQuery["document"];

export function useDocument(
  documentId: string,
  options?: UseQueryOptions<DocumentQuery, unknown, DocumentQuery, QueryKey>,
) {
  const { data, ...rest } = useGraphQlQuery(
    ["document", documentId],
    documentQuery,
    [{ id: documentId }],
    {
      ...options,
      staleTime: 55 * 60 * 1000, // 55 minutes
      cacheTime: 60 * 60 * 1000, // 60 minutes
    },
  );

  return { data: data?.document, ...rest };
}
