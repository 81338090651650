import React, { useState } from "react";
import { useIsDevCycleInitialized } from "@devcycle/react-client-sdk";
import { Button, Result } from "antd5";
import { Redirect } from "wouter";

import { useVariableValue } from "../../lib/featureFlags";
import { QualifyNoticeRequest_LlmProviderEnum_1 as LLMProvider } from "../../lib/generated/app-service-gql/graphql";
import { useNoticeDocuments } from "../../lib/hooks/api/bid/useNoticeDocuments";
import { useNoticeQualification } from "../../lib/hooks/api/bid/useNoticeQualification";
import { useLocalStorage } from "../../lib/hooks/useLocalStorage";
import { DetailsContent, DetailsHeader, DetailsPage } from "../app_layout/DetailsLayout";
import LoadingState from "../buyer_details/question_panel/LoadingState";
import { NoticeDocumentUpload } from "./notice_document_upload/NoticeDocumentUpload";
import { NoticeQualifcationForm, QualifcationFormValues } from "./NoticeQualificationForm";
import { NoticeQualifcationResult } from "./NoticeQualificationResult";

export function NoticeQualificationPage({ noticeId }: { noticeId: string }) {
  const devCycleReady = useIsDevCycleInitialized();
  const isEnabled = useVariableValue("notice-auto-qualification", false);
  if (devCycleReady && !isEnabled) {
    return <Redirect to="/" />;
  }
  return (
    <DetailsPage>
      <DetailsHeader>
        <h1>Notice Qualification</h1>
      </DetailsHeader>
      <DetailsContent>
        <NoticeQualifcation noticeId={noticeId} />
      </DetailsContent>
    </DetailsPage>
  );
}

const DEFAULT_PROMPT = `We are trying to pull answers from the documents related to a government tender.
Keep answers to the questions short concise and factual in short JSON object.
After each answer provide which documents were used for the answer with the corresponding section and any relevant context
for example in the query was "deadline for questions" the short JSON output would be like below
{
  "answer": "10 a.m. UK time on Friday, 23 August 2024",
  "document" : "tender_doc.docx",
  "section" : "Section A",
  "chunk" : "106"
}

You will be provided a set of functions that you can use to search and retrieve the documents to help answer the query.

Query: {{ question }}

I just want JSON returned. Nothing else!
`;

function NoticeQualifcation({ noticeId }: { noticeId: string }) {
  const {
    data: documents,
    isLoading: isLoadingDocs,
    isError: isErrorDocs,
    refetch: refetchDocs,
  } = useNoticeDocuments(noticeId);
  const [showUploadForm, setShowUploadForm] = useState(true);
  const [formValues, setFormValues] = useLocalStorage<QualifcationFormValues>(
    "noticeQualificationForm",
    {
      llmPrompt: DEFAULT_PROMPT,
      noticeId,
      qualificationQuestions: [],
      llmProvider: LLMProvider.Mistral,
    },
  );
  const {
    mutate: qualifyNotice,
    isLoading: isLoadingQualify,
    isError: isErrorQualify,
    reset,
    data,
  } = useNoticeQualification();

  const isLoading = isLoadingDocs || isLoadingQualify;
  const isError = isErrorDocs || isErrorQualify;

  if (isLoading) {
    // this is temporarily borrowing the loading state from the question panel
    return <LoadingState />;
  }

  if (isError) {
    return (
      <Result
        status="error"
        title="Error"
        subTitle="There was an error qualifying the notice"
        extra={<Button onClick={() => (showUploadForm ? refetchDocs() : reset())}>Restart</Button>}
      />
    );
  }

  if (showUploadForm) {
    return (
      <NoticeDocumentUpload
        noticeId={noticeId}
        onComplete={() => setShowUploadForm(false)}
        documents={documents}
      />
    );
  }

  if (data) {
    return (
      <NoticeQualifcationResult
        result={data.result}
        onRetry={() => qualifyNotice({ input: { ...formValues, noticeId } })}
        onEdit={() => reset()}
      />
    );
  }

  return (
    <NoticeQualifcationForm
      onSubmit={(values) => {
        setFormValues(values);
        qualifyNotice({ input: values });
      }}
      noticeId={noticeId}
      defaultValues={{
        ...formValues,
        noticeId,
      }}
    />
  );
}
