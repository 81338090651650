import React, { useMemo } from "react";
import { hot } from "react-hot-loader/root";
import { Tabs, TabsProps, Tooltip } from "antd5";
import { Redirect, Route, Router, Switch, useLocation } from "wouter";

import { withAppLayout } from "components/app_layout/AppLayout";
import { DetailsPage } from "components/app_layout/DetailsLayout";
import { FilterableFrameworkBuyersTable } from "components/buyers/FrameworkBuyersTable";
import { FilterableCallOffs } from "components/call_off/FilterableCallOffs";
import FrameworkHeader from "components/frameworks/FrameworkHeader";
import FrameworkOverview from "components/frameworks/FrameworkOverview";
import { FrameworkSuppliersTable } from "components/frameworks/FrameworkSuppliersTable";
import CentredSpinner from "lib/core_components/CentredSpinner";
import { NEW_HIGHLIGHT_API, useVariableValue } from "lib/featureFlags";
import FeatureToggles, { Feature } from "lib/FeatureToggles";
import { useDescribeFramework } from "lib/hooks/api/frameworks/useDescribeFramework";
import { useFrameworkHighlight } from "lib/hooks/api/frameworks/useFrameworkHighlight";
import { useConfirmUserDataSubscription } from "lib/providers/Paywall";
import { useSubscription } from "lib/providers/Subscription";
import { EventNames, logEvent } from "lib/tracking";

import css from "./FrameworkDetailsPage.module.scss";

enum PrimaryTab {
  OVERVIEW = "/",
  BUYERS = "/buyers",
  SUPPLIERS = "/suppliers",
  CALL_OFFS = "/call-offs",
  RAW_DATA = "/top-level-raw-data",
  EVENT_RAW_DATA = "/event-raw-data",
}

const primaryTabToLabel: Record<PrimaryTab, string> = {
  [PrimaryTab.OVERVIEW]: "Overview",
  [PrimaryTab.BUYERS]: "Buyers",
  [PrimaryTab.SUPPLIERS]: "Suppliers",
  [PrimaryTab.CALL_OFFS]: "Call-offs",

  // Just adding these so eslint doen't complain
  [PrimaryTab.RAW_DATA]: "Top level raw data",
  [PrimaryTab.EVENT_RAW_DATA]: "Framework event raw data",
};

function getSelectedTab(
  location: string,
  userIsAdmin?: boolean,
  hasCallOffsEnabled?: boolean,
): PrimaryTab {
  const locWithoutTrailingSlash =
    location === "/" ? location : location.match(/(.+)\/$/)?.[1] || location;
  switch (locWithoutTrailingSlash) {
    case PrimaryTab.BUYERS:
      return PrimaryTab.BUYERS;
    case PrimaryTab.SUPPLIERS:
      return PrimaryTab.SUPPLIERS;
    case PrimaryTab.CALL_OFFS:
      return hasCallOffsEnabled ? PrimaryTab.CALL_OFFS : PrimaryTab.OVERVIEW;

    // If user isn't admin, then if one of these tabs are routed to, we navigate to overview
    case PrimaryTab.RAW_DATA:
      return userIsAdmin ? PrimaryTab.RAW_DATA : PrimaryTab.OVERVIEW;
    case PrimaryTab.EVENT_RAW_DATA:
      return userIsAdmin ? PrimaryTab.EVENT_RAW_DATA : PrimaryTab.OVERVIEW;

    default:
      return PrimaryTab.OVERVIEW;
  }
}

export function FrameworkDetailsPageWrapper(_props: Props) {
  return (
    <Router base="/frameworks">
      <Switch>
        <Route path="/:framework_id">
          {(param) => <FrameworkDetailsPage framework_id={param.framework_id} />}
        </Route>
        <Route path="/:framework_id/:tab+">
          {(param) => <FrameworkDetailsPage framework_id={param.framework_id} />}
        </Route>
      </Switch>
    </Router>
  );
}

type Props = {
  framework_id: string;
};

export function FrameworkDetailsPage({ framework_id }: Props) {
  const subscription = useSubscription();
  const hasCallOffsEnabled = FeatureToggles.isEnabled(Feature.CALL_OFFS);

  // Will automatically redirect a normal user if they somehow got here
  // (perhaps by direct link) - should exclude guest users
  useConfirmUserDataSubscription("FRAMEWORKS", "FRAMEWORKS");
  const hasFrameworksEnabled =
    subscription.hasDataTypes("FRAMEWORKS") && FeatureToggles.isEnabled(Feature.FRAMEWORK_DETAILS);

  const isHighlightingEnabled = useVariableValue(NEW_HIGHLIGHT_API, false);

  const { data: framework, isLoading } = useDescribeFramework(framework_id, {
    onSuccess: (data) => {
      document.title = `${data.framework.title}`;
    },
    enabled: hasFrameworksEnabled,
  });

  const { data: highlightTerms } = useFrameworkHighlight(
    { frameworkId: framework_id, highlightSignals: true },
    {
      enabled: hasFrameworksEnabled && isHighlightingEnabled,
    },
  );

  const [location, navigate] = useLocation();

  const [tabConfig, setTabConfig] = React.useState(() =>
    getSelectedTab(location.replace(`/${framework_id}`, "") as PrimaryTab),
  );

  React.useEffect(
    () =>
      setTabConfig(
        getSelectedTab(
          location.replace(`/${framework_id}`, ""),
          window.currentUser?.admin,
          hasCallOffsEnabled,
        ),
      ),
    [location, tabConfig, framework_id, hasCallOffsEnabled],
  );

  const tabContent = useMemo(() => {
    switch (tabConfig) {
      case PrimaryTab.RAW_DATA:
        return (
          <pre style={{ whiteSpace: "pre-wrap", wordBreak: "break-word", padding: "16px" }}>
            <code>{framework?.debugInfo ? framework.debugInfo.frameworkInfo : ""}</code>
          </pre>
        );
      case PrimaryTab.EVENT_RAW_DATA:
        return (
          <pre style={{ whiteSpace: "pre-wrap", wordBreak: "break-word", padding: "16px" }}>
            <code>{framework?.debugInfo ? framework.debugInfo.frameworkEventInfo : ""}</code>
          </pre>
        );
      case PrimaryTab.SUPPLIERS:
        return isLoading || !framework ? (
          <CentredSpinner />
        ) : (
          <div className={css.tableContainer}>
            <FrameworkSuppliersTable
              frameworkId={framework.id}
              hiddenFilterSections={["buyers", "supplierGuids", "signals"]}
            />
          </div>
        );

      case PrimaryTab.CALL_OFFS:
        return (
          <div className={css.tableContainer}>
            {framework ? <FilterableCallOffs frameworkId={framework.id} /> : <CentredSpinner />}
          </div>
        );

      case PrimaryTab.BUYERS:
        return !framework?.buyers?.length || isLoading ? (
          <CentredSpinner />
        ) : (
          <div className={css.tableContainer}>
            <FilterableFrameworkBuyersTable
              hiddenSections={["suppliers", "signals"]}
              frameworkId={framework.id}
            />
          </div>
        );

      default:
        return (
          <FrameworkOverview
            framework={framework}
            highlights={highlightTerms}
            isLoading={isLoading}
          />
        );
    }
  }, [framework, isLoading, tabConfig, highlightTerms]);

  if (!hasFrameworksEnabled) {
    return <Redirect to="/" replace={true} />;
  }

  const tabs: TabsProps["items"] = [
    {
      key: PrimaryTab.OVERVIEW,
      label: "Overview",
    },
    {
      key: PrimaryTab.SUPPLIERS,
      label: (
        <Tooltip
          title={
            framework?.suppliers?.length === 0 && (
              <span>
                No suppliers have been awarded to this framework.
                <br />
                <br />
                This can often be the case for frameworks that are not yet live
              </span>
            )
          }
        >
          <span>Suppliers</span>
        </Tooltip>
      ),

      disabled: !framework || framework?.suppliers?.length === 0,
      // Stops this component and by extension the APIs it calls from being rendered until the framework has been fetched
    },
  ];

  if (hasCallOffsEnabled) {
    tabs.push({
      key: PrimaryTab.BUYERS,
      label: (
        <Tooltip
          title={
            framework?.buyers?.length ? undefined : (
              <span>
                No specific buyers can be identified for this framework.
                <br />
                <br />
                This can sometimes be the case for frameworks that are not yet live
              </span>
            )
          }
        >
          <span>Buyers</span>
        </Tooltip>
      ),
      disabled: !framework?.buyers?.length,
    });
    tabs.push({ key: PrimaryTab.CALL_OFFS, label: "Call-off notices", disabled: false });
  }

  if (window.currentUser?.admin && FeatureToggles.isEnabled(Feature.SHOW_DEBUG_DATA)) {
    tabs.push(
      {
        key: PrimaryTab.RAW_DATA,
        label: "Top level raw data",
      },
      {
        key: PrimaryTab.EVENT_RAW_DATA,
        label: "Framework event raw data",
      },
    );
  }

  return (
    <DetailsPage>
      <div className={css.pageHeader}>
        <div className={css.headerComponents}>
          <FrameworkHeader framework={framework} title={highlightTerms?.title || []} />
          <Tabs
            className={css.menuTabs}
            defaultActiveKey={PrimaryTab.OVERVIEW}
            aria-label="Framework details tabs"
            activeKey={getSelectedTab(
              location.replace(`/${framework_id}`, ""),
              window.currentUser?.admin,
              hasCallOffsEnabled,
            )}
            onChange={(key) => {
              navigate(`/${framework_id}${key}`);
              logEvent(EventNames.tabChanged, {
                "Tab selected": primaryTabToLabel[key as PrimaryTab],
              });
            }}
            items={tabs}
            tabBarStyle={{ marginBottom: "0px" }}
          />
        </div>
      </div>
      <div className={css.pageWrapper}>{tabContent}</div>
    </DetailsPage>
  );
}

export default hot(
  withAppLayout(FrameworkDetailsPageWrapper, (props) => ({
    pageName: "Framework details",
    trackingData: { "Framework ID": props.framework_id },
  })),
);
